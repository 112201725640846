<template>
	<div>
		<router-view />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		beforeCreate() {
			if(localStorage.getItem("blwytoken")){
				
			}else {
				let code = Request("code")
				let state = Request("state")
				if(code&&!state){//静默授权
					this.$http.post("/api/code2openid",{code:code}).then(res=>{
						if(res.data.token){
							localStorage.setItem("blwytoken",res.data.token)
							//检查用户是否授权
							this.$http.post("/api/get_shop_info").then(shop=>{
								if(!shop.avatar&&!shop.nickname){
									localStorage.removeItem("blwytoken")
									let url=`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6dbcec89883981ad&redirect_uri=http%3A%2F%2Fblwy.mmteck.cn%2Fh5Me&response_type=code&scope=snsapi_userinfo&state=user#wechat_redirect`
									window.location.href = url
								}
							})
							
						}else{
							alert("获取用户失败，请取消关注再关注试试")
						}
					})
				}else if(code&&state=='user'){//静默授权
					this.$http.post("/api/code2userinfo",{code:code}).then(res=>{
						if(res.data.token){
							localStorage.setItem("blwytoken",res.data.token)
						}else{
							alert("获取用户失败，请取消关注再关注试试")
						}
					})
				}
			}
			
		},
		mounted() {
			
			
		},
		methods: {
			
			

		}
	}
</script>

<style scoped>
	
</style>
